






















import { SelectOption } from '@/models/form'
import Vue from 'vue'
import TerminalCustomSelect from '../TradesTerminal/components/TerminalFormFilter/TerminalCustomSelect.vue'

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    allExchangeOption: {
      type: Array as () => SelectOption[],
      required: false
    }
  },

  components: {
    TerminalCustomSelect
  },

  computed: {
    accountOptions(): SelectOption[] {
      const accountOptions = [...this.allExchangeOption]
      accountOptions.splice(0, 1)

      return accountOptions
    }
  },

  data(): {
    accountSelected?: SelectOption
  } {
    return {
      accountSelected: this.allExchangeOption[1]
    }
  },

  methods: {
    submit() {
      this.$emit('on-submit', this.accountSelected)
    }
  }
})
